import React from 'react'
import "./eksamenacija.scss"

export default function Eksamenacija() {
  return (
    <div className='eksamenacija'>
        <h2>Eksaminācija</h2>
        <h4>Lapa ir izstrādes stadijā.</h4>
    </div>
  )
}
