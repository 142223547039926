import React from 'react'
import './otherComponentsStyles.scss';

export default function Contryes({ selectedCountry, onCountryChange }) {


  return (
    <div className='contryes-style'>
        <label for="country">Выберите страну:</label>
            <select id="country" name="country" value={selectedCountry} onChange={onCountryChange}>
            <option value="AF">Афганистан</option>
            <option value="AL">Албания</option>
            <option value="DZ">Алжир</option>
            <option value="AS">Американское Самоа</option>
            <option value="AD">Андорра</option>
            <option value="AO">Ангола</option>
            <option value="AI">Ангилья</option>
            <option value="AQ">Антарктида</option>
            <option value="AG">Антигуа и Барбуда</option>
            <option value="AR">Аргентина</option>
            <option value="AM">Армения</option>
            <option value="AW">Аруба</option>
            <option value="AU">Австралия</option>
            <option value="AT">Австрия</option>
            <option value="AZ">Азербайджан</option>
            <option value="BS">Багамы</option>
            <option value="BH">Бахрейн</option>
            <option value="BD">Бангладеш</option>
            <option value="BB">Барбадос</option>
            <option value="BY">Беларусь</option>
            <option value="BE">Бельгия</option>
            <option value="BZ">Белиз</option>
            <option value="BJ">Бенин</option>
            <option value="BM">Бермуды</option>
            <option value="BT">Бутан</option>
            <option value="BO">Боливия</option>
            <option value="BQ">Бонэйр, Синт-Эстатиус и Саба</option>
            <option value="BA">Босния и Герцеговина</option>
            <option value="BW">Ботсвана</option>
            <option value="BV">Остров Буве</option>
            <option value="BR">Бразилия</option>
            <option value="IO">Британская территория в Индийском океане</option>
            <option value="BN">Бруней-Даруссалам</option>
            <option value="BG">Болгария</option>
            <option value="BF">Буркина-Фасо</option>
            <option value="BI">Бурунди</option>
            <option value="CV">Кабо-Верде</option>
            <option value="KH">Камбоджа</option>
            <option value="CM">Камерун</option>
            <option value="CA">Канада</option>
            <option value="KY">Каймановы острова</option>
            <option value="CF">Центральноафриканская Республика</option>
            <option value="TD">Чад</option>
            <option value="CL">Чили</option>
            <option value="CN">Китай</option>
            <option value="CX">Остров Рождества</option>
            <option value="CC">Кокосовые острова</option>
            <option value="CO">Колумбия</option>
            <option value="KM">Коморы</option>
            <option value="CG">Конго</option>
            <option value="CD">Конго, Демократическая Республика</option>
            <option value="CK">Острова Кука</option>
            <option value="CR">Коста-Рика</option>
            <option value="HR">Хорватия</option>
            <option value="CU">Куба</option>
            <option value="CW">Кюрасао</option>
            <option value="CY">Кипр</option>
            <option value="CZ">Чешская Республика</option>
            <option value="DK">Дания</option>
            <option value="DJ">Джибути</option>
            <option value="DM">Доминика</option>
            <option value="DO">Доминиканская Республика</option>
            <option value="EC">Эквадор</option>
            <option value="EG">Египет</option>
            <option value="SV">Сальвадор</option>
            <option value="GQ">Экваториальная Гвинея</option>
            <option value="ER">Эритрея</option>
            <option value="EE">Эстония</option>
            <option value="ET">Эфиопия</option>
            <option value="FK">Фолклендские острова (Мальвинские)</option>
            <option value="FO">Фарерские острова</option>
            <option value="FJ">Фиджи</option>
            <option value="FI">Финляндия</option>
            <option value="FR">Франция</option>
            <option value="GF">Французская Гвиана</option>
            <option value="PF">Французская Полинезия</option>
            <option value="TF">Французские Южные территории</option>
            <option value="GA">Габон</option>
            <option value="GM">Гамбия</option>
            <option value="GE">Грузия</option>
            <option value="DE">Германия</option>
            <option value="GH">Гана</option>
            <option value="GI">Гибралтар</option>
            <option value="GR">Греция</option>
            <option value="GL">Гренландия</option>
            <option value="GD">Гренада</option>
            <option value="GP">Гваделупа</option>
            <option value="GU">Гуам</option>
            <option value="GT">Гватемала</option>
            <option value="GG">Гернси</option>
            <option value="GN">Гвинея</option>
            <option value="GW">Гвинея-Бисау</option>
            <option value="GY">Гайана</option>
            <option value="HT">Гаити</option>
            <option value="HM">Остров Херд и острова Макдональд</option>
            <option value="VA">Святой Престол (Государство-город Ватикан)</option>
            <option value="HN">Гондурас</option>
            <option value="HK">Гонконг</option>
            <option value="HU">Венгрия</option>
            <option value="IS">Исландия</option>
            <option value="IN">Индия</option>
            <option value="ID">Индонезия</option>
            <option value="IR">Иран, Исламская Республика</option>
            <option value="IQ">Ирак</option>
            <option value="IE">Ирландия</option>
            <option value="IM">Остров Мэн</option>
            <option value="IL">Израиль</option>
            <option value="IT">Италия</option>
            <option value="JM">Ямайка</option>
            <option value="JP">Япония</option>
            <option value="JE">Джерси</option>
            <option value="JO">Иордания</option>
            <option value="KZ">Казахстан</option>
            <option value="KE">Кения</option>
            <option value="KI">Кирибати</option>
            <option value="KP">Корейская Народно-Демократическая Республика</option>
            <option value="KR">Корея, Республика</option>
            <option value="KW">Кувейт</option>
            <option value="KG">Кыргызстан</option>
            <option value="LA">Лаосская Народно-Демократическая Республика</option>
            <option value="LV">Латвия</option>
            <option value="LB">Ливан</option>
            <option value="LS">Лесото</option>
            <option value="LR">Либерия</option>
            <option value="LY">Ливийская Арабская Джамахирия</option>
            <option value="LI">Лихтенштейн</option>
            <option value="LT">Литва</option>
            <option value="LU">Люксембург</option>
            <option value="MO">Макао</option>
            <option value="MK">Македония</option>
            <option value="MG">Мадагаскар</option>
            <option value="MW">Малави</option>
            <option value="MY">Малайзия</option>
            <option value="MV">Мальдивы</option>
            <option value="ML">Мали</option>
            <option value="MT">Мальта</option>
            <option value="MH">Маршалловы Острова</option>
            <option value="MQ">Мартиника</option>
            <option value="MR">Мавритания</option>
            <option value="MU">Маврикий</option>
            <option value="YT">Майотта</option>
            <option value="MX">Мексика</option>
            <option value="FM">Федеративные Штаты Микронезии</option>
            <option value="MD">Молдова, Республика</option>
            <option value="MC">Монако</option>
            <option value="MN">Монголия</option>
            <option value="ME">Черногория</option>
            <option value="MS">Монтсеррат</option>
            <option value="MA">Марокко</option>
            <option value="MZ">Мозамбик</option>
            <option value="MM">Мьянма</option>
            <option value="NA">Намибия</option>
            <option value="NR">Науру</option>
            <option value="NP">Непал</option>
            <option value="NL">Нидерланды</option>
            <option value="NC">Новая Каледония</option>
            <option value="NZ">Новая Зеландия</option>
            <option value="NI">Никарагуа</option>
            <option value="NE">Нигер</option>
            <option value="NG">Нигерия</option>
            <option value="NU">Ниуэ</option>
            <option value="NF">Остров Норфолк</option>
            <option value="MP">Северные Марианские острова</option>
            <option value="NO">Норвегия</option>
            <option value="OM">Оман</option>
            <option value="PK">Пакистан</option>
            <option value="PW">Палау</option>
            <option value="PS">Палестинская территория, оккупированная</option>
            <option value="PA">Панама</option>
            <option value="PG">Папуа-Новая Гвинея</option>
            <option value="PY">Парагвай</option>
            <option value="PE">Перу</option>
            <option value="PH">Филиппины</option>
            <option value="PN">Питкэрн</option>
            <option value="PL">Польша</option>
            <option value="PT">Португалия</option>
            <option value="PR">Пуэрто-Рико</option>
            <option value="QA">Катар</option>
            <option value="RE">Реюньон</option>
            <option value="RO">Румыния</option>
            <option value="RU">Российская Федерация</option>
            <option value="RW">Руанда</option>
            <option value="BL">Сен-Бартелеми</option>
            <option value="SH">Остров Святой Елены</option>
            <option value="KN">Сент-Китс и Невис</option>
            <option value="LC">Сент-Люсия</option>
            <option value="MF">Сен-Мартен</option>
            <option value="PM">Сен-Пьер и Микелон</option>
            <option value="VC">Сент-Винсент и Гренадины</option>
            <option value="WS">Самоа</option>
            <option value="SM">Сан-Марино</option>
            <option value="ST">Сан-Томе и Принсипи</option>
            <option value="SA">Саудовская Аравия</option>
            <option value="SN">Сенегал</option>
            <option value="RS">Сербия</option>
            <option value="SC">Сейшелы</option>
            <option value="SL">Сьерра-Леоне</option>
            <option value="SG">Сингапур</option>
            <option value="SX">Синт-Мартен</option>
            <option value="SK">Словакия</option>
            <option value="SI">Словения</option>
            <option value="SB">Соломоновы Острова</option>
            <option value="SO">Сомали</option>
            <option value="ZA">Южная Африка</option>
            <option value="GS">Южная Георгия и Южные Сандвичевы острова</option>
            <option value="SS">Южный Судан</option>
            <option value="ES">Испания</option>
            <option value="LK">Шри-Ланка</option>
            <option value="SD">Судан</option>
            <option value="SR">Суринам</option>
            <option value="SJ">Шпицберген и Ян Майен</option>
            <option value="SZ">Эсватини</option>
            <option value="SE">Швеция</option>
            <option value="CH">Швейцария</option>
            <option value="SY">Сирийская Арабская Республика</option>
            <option value="TW">Тайвань, Китай</option>
            <option value="TJ">Таджикистан</option>
            <option value="TZ">Танзания, Объединенная Республика</option>
            <option value="TH">Таиланд</option>
            <option value="TL">Тимор-Лешти</option>
            <option value="TG">Того</option>
            <option value="TK">Токелау</option>
            <option value="TO">Тонга</option>
            <option value="TT">Тринидад и Тобаго</option>
            <option value="TN">Тунис</option>
            <option value="TR">Турция</option>
            <option value="TM">Туркменистан</option>
            <option value="TC">Теркс и Кайкос</option>
            <option value="TV">Тувалу</option>
            <option value="UG">Уганда</option>
            <option value="UA">Украина</option>
            <option value="AE">Объединенные Арабские Эмираты</option>
            <option value="GB">Великобритания</option>
            <option value="US">Соединенные Штаты</option>
            <option value="UM">Внешние малые острова США</option>
            <option value="UY">Уругвай</option>
            <option value="UZ">Узбекистан</option>
            <option value="VU">Вануату</option>
            <option value="VE">Венесуэла</option>
            <option value="VN">Вьетнам</option>
            <option value="VG">Виргинские острова, Британские</option>
            <option value="VI">Виргинские острова, США</option>
            <option value="WF">Уоллис и Футуна</option>
            <option value="EH">Западная Сахара</option>
            <option value="YE">Йемен</option>
            <option value="ZM">Замбия</option>
            <option value="ZW">Зимбабве</option>
            </select>
    </div>
  )
}
