import React from 'react'
import BiedriCard from './BiedriCard.jsx'
import BiedriCardWithOutLogo from './BiedriCardWithOutLogo.jsx'
import ScrollToTop from '../../../Components/Main/OrhetComponents/ScrollToTop.jsx'
import fcImg from './img/fc.jpg'
import bbc from './img/BBC.png'
import kbs from './img/KBS-Riga-01.png'
import Kuldiga from './img/Cīņu_klubs_Kuldīga.jpeg'
import Favorit from './img/favorits.jpg'
import skits from './img/LOGO-SKITS-oreginal2.jpg'
import ltcs from './img/Logo_LTCS_s.jpg'
import topRing from './img/TOP_RING LOGO_GOLD PRINT2.jpg'
import mtl from './img/Muay_Thai_Liga.jpg'
import Silver_griffin from './img/Silver_Griffin.jpg'
import LiepajaKik from './img/LIepaja_Kik_team.jpg'
import Tkachenko from './img/TkachenkoTeam.png'
import Galeev from './img/GaleevTeam.jpg'
import GoldenGlory from './img/GoldenGlory.jpg'
import MTA from './img/MTA.jpg'
import LČG from './img/LČG.jpg'
import LSPA from './img/LSPA.jpg'
import TMG from './img/TMG.jpg'
import VKL from './img/VKL.jpg'
import './biedri.scss'
import MapWithClubs from '../../../Components/Main/OrhetComponents/MapWithClubs.jsx'

export default function Biedri() {
  return (
    <>
    <ScrollToTop />
      <h2 class="display-center">
        Latvijas Kikboksa Federācijas Biedri
      </h2>
      <div className='biedri-cards'>
          <div><BiedriCard img={Favorit} clubTitle={'Sporta klubs „Favorīts”'} city={'Mālu iela 10, Ventspils, LV-3604'} epasts={''} phone={''}/></div>
          <div><BiedriCard img={skits} clubTitle={'Cīņu mākslas sporta klubs "Skits"'} city={'Fabrikas iela 2, Līvāni, Līvānu pilsēta, Līvānu novads, LV-5316'} epasts={'skits.livani@inbox.lv'} phone={'+37126720077'}/></div>
          <div><BiedriCard img={kbs} clubTitle={'Kikboksa un boksa skola "Rīga"'} city={'Klusā iela 12 K-3, Vidzemes priekšpilsēta, Rīga, LV-1013'} epasts={''} phone={'+37129677741'}/></div>
          <div><BiedriCard img={bbc} clubTitle={'Ballistic Boxing Club'} city={' Malkas iela 4, Liepāja, LV-3401'} epasts={''} phone={''}/></div>
          <div><BiedriCard img={Kuldiga} clubTitle={'Cīņu klubs Kuldīga'} city={'Kuldīgas nov., Kuldīga, Vienības iela 87, LV-3301'} epasts={''} phone={''}/></div>
          <div><BiedriCard img={ltcs} clubTitle={'Sporta klubs "LTCS"'} city={'Rīga, Vaidavas iela 3 - 2, LV-1084'} epasts={''} phone={''}/></div>
          <div><BiedriCard img={topRing} clubTitle={'Top Ring'} city={'Lubānas iela 78b, Latgales priekšpilsēta, Rīga, LV-1073'} epasts={'topringlat@gmail.com'} phone={'+37122365002'}/></div>
          <div><BiedriCard img={Galeev} clubTitle={'MUAY THAI SPORTA SKOLA RĪGA'} city={'Burtnieku-37, Rīga.'} epasts={''} phone={''}/></div>
          <div><BiedriCard img={mtl} clubTitle={'Latvijas Muay Thai Līga'} city={'Rīga'} epasts={''} phone={''}/></div>
          <div><BiedriCard img={Tkachenko} clubTitle={'Tkachenko Team'} city={'Brīvības iela 148, LV-1012'} epasts={''} phone={''}/></div>
          <div><BiedriCard img={fcImg} clubTitle={'Fun Catchers'} city={'Līči, Stopiņu pag., Ropažu nov.'} epasts={'funcatcherslv@gmail.com'} phone={'+37129427994'}/></div>
          <div><BiedriCardWithOutLogo clubTitle={'Cīņu Mākslas Centrs BUDO'} city={'Latvija'} epasts={''} phone={''}/></div>
          <div><BiedriCard img={Silver_griffin} clubTitle={'Silver Griffin'} city={'Rēzekne, Atbrīvošanas aleja 166A'} epasts={''} phone={''}/></div>
          <div><BiedriCard img={LiepajaKik} clubTitle={'Liepājas Kikboksinga Klubs “K Sports”'} city={'Brīvības iela 117, Liepāja, LV-3401'} epasts={''} phone={''}/></div>
          <div><BiedriCard img={MTA} clubTitle={'Bērnu un jauniešu centrs "Bolderāja"'} city={'Rīga'} epasts={''} phone={''}/></div>
          <div><BiedriCard img={LSPA} clubTitle={'LSPA sporta klubs'} city={'Rīga'} epasts={''} phone={''}/></div>
          <div><BiedriCard img={VKL} clubTitle={'Ventspils Kikboksa Leģions'} city={'Olimpiskais centrs "Ventspils" Sporta iela 7/9, Ventspilī'} epasts={'kikboksalegions@gmail.com'} phone={'+37122360572'}/></div>
          <div><BiedriCard img={TMG} clubTitle={'TM Gym'} city={'Rīga'} epasts={''} phone={''}/></div>
          <div><BiedriCard img={LČG} clubTitle={'Lāčplēša Cīņu Leģions'} city={'Ķekava'} epasts={''} phone={''}/></div>
          <div><BiedriCard img={GoldenGlory} clubTitle={'Golden Glory'} city={'Rīga'} epasts={''} phone={''}/></div>
          <div><BiedriCardWithOutLogo clubTitle={'Boksa un kikboksa klubs "Olympic Boxing Team"'} city={'Rīga'} epasts={''} phone={''}/></div>
      </div>
      <div className='ClubMap'>
        <MapWithClubs/>
      </div>
    </>
  )
}
